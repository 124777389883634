.header {
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	top: 0;
	position: fixed;
	background-color: #fff;
	z-index: 999;
}

.logo {
	flex-basis: 30%;
	padding-left: 30px;
}

.links {
	height: 100%;
	flex-basis: 70%;
	display: flex;
	flex-flow: row;
	justify-content: flex-end;

	list-style: none;
	margin: 0;
}

.linkWrapper {
	background-color: #fff;

	-webkit-transition: background-color 0.5s;
	-moz-transition: background-color 0.5s;
	-ms-transition: background-color 0.5s;
	-o-transition: background-color 0.5s;
	transition: background-color 0.5s;
	transition-property: background-color;
}

.linkWrapper:hover {
	background-color: #eee;
}

.link {
	text-decoration: none;
	text-transform: uppercase;
	color: #000;
	display: block;
	padding: 1.8em 2em;
}

.cart {
	display: flex;
	align-items: center;
}
