.input {
    display: flex;
    justify-content: space-between;
    min-width: 600px;
    max-width: 600px;
    margin-bottom: 20px;

    > div {
        text-transform: uppercase;
    }

    input {
        width: 300px;
        height: 20px;
        font-size: 18px;
        padding: 5px;
        border: 1px solid #aaa;
        border-radius: 5px;
    }
}

.textArea {
    resize: none;
    font-family: inherit;
    font-size: 18px;
    padding: 5px;
    border: 1px solid #aaa;
    border-radius: 5px;
}
