.backgroundImage {
	position: fixed;
	left: 0;
	opacity: 0.8;
	width: 100%;
	z-index: -1;
}

.vendorRegistration {
	background-color: #eee;
	height: 80vh;
	.header {
		font-size: 3em;
		margin: 50px;
	}
	.inputRow {
		min-width: 600px;
	}
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 50px;
}

.cardsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.searchBar {
	input {
		padding: 5px;
		border: none;
		border-radius: 5px;
		margin: 20px;
		font-size: 1.2em;
		text-align: center;
		color: #444;
		// background-color: rgb(219, 219, 219);
		min-width: 20%;
		outline: none;
	}
}

.register {
	position: relative;
	width: 100%;
	height: 90vh;

	.registerCard {
		position: absolute;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
		background-color: #fff;
		border-radius: 5px;
		height: 50%;
		width: 50%;
		left: 25%;
		top: 10%;
		padding: 30px;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 0;
		.header {
			font-size: 3em;
			margin-bottom: 30px;
		}
		.subHeader {
			font-size: 1.5em;
			margin-bottom: 30px;
		}
		.link {
			color: #666;
			cursor: pointer;
		}
		.inputRow {
			min-width: 300px;
		}
	}
}

.product {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 50px;
	background-color: #eee;
	overflow-x: hidden;

	.relatedProducts {
		width: 100%;
	}

	.relatedRow {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		overflow-x: auto;
	}

	.productLayout {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.addToCart {
		outline: none;
		cursor: pointer;
		margin-top: 30px;
		padding: 10px;
		border-radius: 5px;
		font-size: 1.5em;
		border: 1px solid rgb(100, 100, 100);
		background: linear-gradient(45deg, rgb(106, 87, 218), rgb(59, 56, 228));
		color: white;
		&:hover {
			background: linear-gradient(
				45deg,
				rgb(82, 61, 201),
				rgb(36, 33, 168)
			);
		}
	}

	.productDetails {
		background-color: #fff;
		padding: 20px;
		margin-left: 10px;
		margin-right: 10px;
		display: grid;
		grid-template-columns: 1fr 1fr;

		.productInfoLeft {
			.companyName {
				cursor: pointer;
			}
		}

		.productInfoRight {
			margin-top: 30px;
		}

		.fullPrice {
			text-decoration: line-through;
			font-size: 1em;
		}

		.discount {
			font-size: 1.3em;
		}

		.salePrice {
			font-size: 2em;
			color: green;
			font-weight: bold;
		}

		.saleCountdown {
			font-size: 1em;
			font-style: italic;
		}
	}
}

.vendor {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 50px;
	background-color: #eee;
	overflow-x: hidden;

	.vendorDetails {
		background-color: #fff;
		padding: 20px;
		margin-left: 10px;
		margin-right: 10px;
	}
}
.cart {
	display: grid;
	grid-template-columns: 5fr 1fr;
	.items {
		.noItems {
			text-align: center;
		}
		.cartItem {
			border: 1px solid #aaa;
			border-radius: 5px;
			padding: 10px;
			margin: 10px;
			display: flex;
			justify-content: space-between;

			.cartImage {
				width: 200px;
			}

			.productName {
				font-size: 1.2em;
			}

			.productPrice {
				font-size: 2em;
			}
		}
	}
	.checkoutBox {
		padding: 10px;
	}
}
