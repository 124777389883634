.footer {
    background-color: #fff;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    color: #666;
    font-size: 0.8em;
}
