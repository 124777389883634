.card {
	background-color: #fff;
	cursor: pointer;
	padding: 15px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	min-height: 500px;
	min-width: 400px;
	max-width: 400px;
	margin: 20px;
	display: flex;
	flex-direction: column;
}

.image {
	flex: 50%;
}

.content {
	flex: 50%;
	display: flex;
	margin-top: 5px;

	.name {
		flex: 50%;
		font-size: 1.5em;
		text-align: left;
	}

	.prices {
		display: flex;
		flex-direction: column;
		text-align: right;
	}

	.fullPrice {
		text-decoration: line-through;
		font-size: 0.7em;
	}

	.discount {
		font-size: 1em;
	}

	.saleCountdown {
		font-size: 0.7em;
		font-style: italic;
	}

	.salePrice {
		flex: 50%;
		font-size: 1.7em;
		color: green;
		text-align: right;
		font-weight: bold;
	}
}
